import { Col, Image, Modal, Row } from "react-bootstrap";
import { useAuth } from "../../context/auth-context";
import { Button, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { asyncWrap } from "../../utils/utils";
import { useHistory } from "react-router";
import { BsFillCameraFill } from "react-icons/bs";

const GSTModel = ({ show, setShow,userDetails }) => {
  const [gstinNumber, setGstinNumber] = useState(userDetails?.GSTAuto);
  const [gstVerify, setGSTVerify] = useState(userDetails?.IsGSTVerify);
  const [hasdisablebtn, setHadDisableBtn] = useState(true);
  const [disableUploadbtn, setDisableUploadbtn] = useState(false);
  const [gstinImage, setGstinImage] = useState(userDetails?.CancelledCheaquePhoto);
  const { user } = useAuth();
  const history = useHistory();
  const maxSize = 10 * 1024 * 1024;

  useEffect(() => {
    // getUserProfile();
    // eslint-disable-next-line
  }, []);

  const getUserProfile = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (error?.response?.status === 401) {
      Swal.fire("Please Logout and login again");
      return;
    }
    if (!result) {
      console.log(error.response);
      return;
    } else {
      setGstinNumber(result?.data?.data?.GSTAuto);
      setGstinImage(result?.data?.data?.CancelledCheaquePhoto);
      setGSTVerify(result?.data?.data?.IsGSTVerify);
    }
  };

  const handleSubmit = async () => {
    if (gstVerify) {
      Swal.fire("Your information already verify successfully!")
      return
    }

    if (gstinNumber === "") {
      Swal.fire("Please Enter Gstin Number Correctly");
      return;
    }
    const [error, result] = await asyncWrap(
      axios.post("/verifyKyc/verify-gst", {
        gst_number: gstinNumber,
      })
    );
    if (!result) {
      Swal.fire(error.response.data.data.message);
    } else if (result.data?.success) {
      const [userError, userResult] = await asyncWrap(
        axios.post("/editProfile", {
          userType: user?.profile?.roleName || user?.roleName,
          userId: user?.profile?.userId || user.userId,
          gstAuto: gstinNumber,
          cancelledChequePhoto: gstinImage,
          isGSTVerify: true,
          addressLine2: result?.data?.data?.principal_place_address,
        })
      );
      if (!userResult) {
        Swal.fire(error.response.data.data.message);
        history.replace("/home");
      } else if (userResult.data?.success) {
        Swal.fire(userResult.data.message).then(() => {
          history.replace("/home");
        });
      } else {
        Swal.fire(userResult?.data?.message).then(() => {
          window.location.reload();
        });
      }
    }
  };

  const handleUploadGstinImage = async e => {
    const file = e.target.files[0];
    if (file && file.size >= maxSize) {
      Swal.fire("File size exceeds the limit (10MB)");
      return;
    }
    setDisableUploadbtn(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const [error, result] = await asyncWrap(axios.post("/upload", formData));
    if (error) {
      Swal.fire(error.response.data.message);
      return;
    }
    const {
      data: { fileUrl },
    } = result;
    setGstinImage(fileUrl);
    setDisableUploadbtn(false);
  };

  return (
    <Modal fullscreen={true} show={show}>
      <Modal.Body
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "50px",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Form className="w-100">
              <FormGroup floating className="p-0">
                <Input
                  style={{ border: "1px solid #B0B0B0", width: "100%" }}
                  required
                  onChange={e => {
                    setGstinNumber(e.target.value);
                    setHadDisableBtn(false);
                  }}
                  value={gstinNumber}
                  placeholder="Enter GSTIN Number"
                  type="text"
                />
                <Label>GSTIN Number</Label>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row className="w-100">
          <Col>
            <Label style={{ width: "100%" }}>
              GSTIN Image
              <div
                // onClick={() => {
                //   document.getElementById("aadharCardImage")?.click();
                // }}
                style={{
                  background: "#E5ECFD",
                  height: "150px",
                  borderRadius: "10px",
                }}
                className="mt-2 mb-3"
              >
                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                  <BsFillCameraFill
                    style={{
                      color: "#3A69DD",
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </div>
              </div>
              <Input
                hidden
                required
                accept=".png,.jpeg,.jpg"
                id="aadharCardImage"
                onChange={e => handleUploadGstinImage(e)}
                placeholder="Enter Aadhar Card Number"
                style={{ width: "100%" }}
                type="file"
              />
            </Label>
          </Col>
        </Row>
        {gstinImage ? (
          <Row>
            <Col>
              <Image src={gstinImage} width="100%" height="200px" />
            </Col>
          </Row>
        ) : null}
        <Row
          style={{
            background: "#FAFAFA",
            padding: "10px",
            margin: "10px 0px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col className="p-0" style={{ color: "#CC7E02" }}>
            <Button
              disabled={hasdisablebtn && disableUploadbtn}
              style={{
                width: "max-content",
                background: `${disableUploadbtn ? "#ccc" : "#3A69DD"}`,
                color: "white",
                border: "none",
                padding: "0.6rem 2rem",
                marginTop: "0.5rem",
                fontWeight: "600",
                borderRadius: "20px",
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              {disableUploadbtn ? (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Submit"
              )}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default GSTModel;
