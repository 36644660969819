import { Col, Image, Modal, Row } from "react-bootstrap";
import { useAuth } from "../../context/auth-context";
import { Button, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { asyncWrap } from "../../utils/utils";
import { useHistory } from "react-router";
import { BsFillCameraFill } from "react-icons/bs";

const PanOCRModel = ({ show, setShow, userDetails }) => {
  const [image, setImage] = useState();
  const [hasdisablebtn, setHadDisableBtn] = useState(true);
  const [disableUploadbtn, setDisableUploadbtn] = useState(false);
  const [gstinImage, setGstinImage] = useState();
  const [panOcrImageUrl, setPanOcrImageUrl] = useState(
    userDetails?.PANOCRIMAGE
  );
  const [panOcrImageVerify, setPanOcrImageVerify] = useState(
    userDetails?.IsPanOCRVerify
  );
  const { user } = useAuth();
  const history = useHistory();
  const maxSize = 10 * 1024 * 1024;

  useEffect(() => {
    // getUserProfile();
    // eslint-disable-next-line
  }, []);

  const getUserProfile = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (error?.response?.status === 401) {
      Swal.fire("Please Logout and login again");
      return;
    }
    if (!result) {
      console.log(error.response);
      return;
    } else {
      setPanOcrImageUrl(result?.data?.data?.PANOCRIMAGE);
      setPanOcrImageVerify(result?.data?.data?.IsPanOCRVerify);
    }
  };

  const handleSubmit = async () => {
    // if (dlNumber === "") {
    //   Swal.fire("Please Enter Driving Licence Number Correctly");
    //   return;
    // }

    if (panOcrImageVerify) {
      Swal.fire("Your information already verify successfully!")
      return
    }

    const formData = new FormData();
    formData.append("image", image);
    console.log(image);
    const [error, result] = await asyncWrap(
      axios.post("verifyKyc/verify-ocr-pan", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
    if (!result) {
      Swal.fire(error.response.data.data.message);
      history.replace("/home");
    } else if (result.data?.success) {
      const [userError, userResult] = await asyncWrap(
        axios.post("/editProfile", {
          userType: user?.profile?.roleName || user?.roleName,
          userId: user?.profile?.userId || user.userId,
          panOCRImage: result?.data?.data?.pan,
          isPanOCRVerify: true,
        })
      );
      if (!userResult) {
        Swal.fire(error.response.data.data.message);
        history.replace("/home");
      } else if (userResult.data?.success) {
        Swal.fire(userResult.data.message).then(() => {
          history.replace("/home");
        });
      } else {
        Swal.fire(userResult?.data?.message).then(() => {
          window.location.reload();
        });
      }
    } else {
      Swal.fire(result?.data?.message).then(() => {
        window.location.reload();
      });
    }
  };
  const handleUploadPanOCRImage = async e => {
    const file = e.target.files[0];
    if (file && file.size >= maxSize) {
      Swal.fire("File size exceeds the limit (10MB)");
      return;
    }
    setDisableUploadbtn(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const [error, result] = await asyncWrap(axios.post("/upload", formData));
    if (error) {
      Swal.fire(error.response.data.message);
      return;
    }
    const {
      data: { fileUrl },
    } = result;
    setPanOcrImageUrl(fileUrl);
    setImage(e.target.files[0]);
    setDisableUploadbtn(false);
  };

  return (
    <Modal fullscreen={true} show={show}>
      <Modal.Body
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "50px",
        }}
      >
        <Row className="w-100">
          <Col>
            <Label style={{ width: "100%" }}>
              Pan Card Image
              <div
                // onClick={() => {
                //   document.getElementById("aadharCardImage")?.click();
                // }}
                style={{
                  background: "#E5ECFD",
                  height: "150px",
                  borderRadius: "10px",
                }}
                className="mt-2 mb-3"
              >
                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                  <BsFillCameraFill
                    style={{
                      color: "#3A69DD",
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </div>
              </div>
              <Input
                hidden
                required
                accept=".png,.jpeg,.jpg"
                id="aadharCardImage"
                onChange={e => handleUploadPanOCRImage(e)}
                placeholder="Enter Aadhar Card Number"
                style={{ width: "100%" }}
                type="file"
              />
            </Label>
          </Col>
        </Row>
        {panOcrImageUrl ? (
          <Row>
            <Col>
              <Image src={panOcrImageUrl} width="100%" height="200px" />
            </Col>
          </Row>
        ) : null}
        <Row
          style={{
            background: "#FAFAFA",
            padding: "10px",
            margin: "10px 0px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col className="p-0" style={{ color: "#CC7E02" }}>
            <Button
              disabled={hasdisablebtn && disableUploadbtn}
              style={{
                width: "max-content",
                background: `${disableUploadbtn ? "#ccc" : "#3A69DD"}`,
                color: "white",
                border: "none",
                padding: "0.6rem 2rem",
                marginTop: "0.5rem",
                fontWeight: "600",
                borderRadius: "20px",
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              {disableUploadbtn ? (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Submit"
              )}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default PanOCRModel;
