import { Col, Image, Modal, Row } from "react-bootstrap";
import { useAuth } from "../../context/auth-context";
import { Button, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { asyncWrap } from "../../utils/utils";
import { useHistory } from "react-router";
import { BsFillCameraFill } from "react-icons/bs";
import { t } from "i18next";

const BankAccountModel = ({ show, setShow, userDetails }) => {
  console.log(userDetails?.beneficiaryDetail)
  const [account, setAccount] = useState(userDetails?.beneficiaryDetail?.accountNumber);
  const [ifsc, setIfsc] = useState(userDetails?.beneficiaryDetail?.ifscNumber);
  const [name, setName] = useState(userDetails?.beneficiaryDetail?.name);
  const [bankName, setBankName] = useState(userDetails?.beneficiaryDetail?.bankName);
  const [bankVerify, setBankVerify] = useState(userDetails?.IsBankDetailsVerify);
  const [hasdisablebtn, setHadDisableBtn] = useState(true);
  const [disableUploadbtn, setDisableUploadbtn] = useState(false);
  const { user } = useAuth();
  const history = useHistory();

  //eslint-disable-next-line
  const isvalidAccount = new RegExp("[0-9]{9,18}");

  //eslint-disable-next-line
  const isvalidIfsc = new RegExp("^[A-Z]{4}[0][A-Z0-9]{6}$");
  const isvalidPaytm = new RegExp(/^[6-9]\d{9}$/);
  const isvalidUpi = new RegExp("[a-zA-Z0-9.-_]{2,256}@[a-zA-Z]{2,64}");
  const isName = new RegExp(/^[a-zA-Z0-9\s]+$/);

  useEffect(() => {
    // getUserProfile();
    // eslint-disable-next-line
  }, []);

  const getUserProfile = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (error?.response?.status === 401) {
      Swal.fire("Please Logout and login again");
      return;
    }
    if (!result) {
      console.log(error.response);
      return;
    } else {
      setAccount(result?.data?.data?.DrivingLicense);
      setBankVerify(result.data?.data?.IsDrivingLicenceVerify);
    }
  };

  const handleSubmit = async () => {

    if (bankVerify) {
      Swal.fire("Your information already verify successfully!")
      return
    }

    if (!isvalidAccount.test(account)) {
      Swal.fire("Please Enter valid Account Number!");
      return;
    }
    if (!isvalidIfsc.test(ifsc)) {
      Swal.fire("Please Enter valid Ifsc Code!");
      return;
    }

    const [error, result] = await asyncWrap(
      axios.post("verifyKyc/verify-bank", {
        bankAccount: account,
        ifsc: ifsc,
        name: name,
      })
    );
    if (!result) {
      Swal.fire(error.response.data.data.message);
      history.replace("/home");
    } else if (result.data?.success) {
      const [userError, userResult] = await asyncWrap(
        axios.post("/editProfile",  {
          beneficiaryDetail: {
            name: result?.data?.data?.data?.nameAtBank,
            accountNumber: account,
            ifscNumber: ifsc,
            bankName: bankName
          },
          isBankDetailsVerify: true
        })
      );
      if (!userResult) {
        Swal.fire(error.response.data.data.message);
        history.replace("/home");
      } else if (userResult.data?.success) {
        Swal.fire(userResult.data.message).then(() => {
          history.replace("/home");
        });
      } else {
        Swal.fire(userResult?.data?.message).then(() => {
          window.location.reload();
        });
      }
    } else {
      Swal.fire(result?.data?.message).then(() => {
        window.location.reload();
      });
    }
  };

  return (
    <Modal fullscreen={true} show={show}>
      <Modal.Body
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "50px",
        }}
      >
        <FormGroup floating className="p-0 w-100">
          <Input
            style={{ border: "1px solid #B0B0B0", width: "100%" }}
            required
            id="account_number"
            value={account}
            // disabled={!disableEditDetail}
            onChange={e => setAccount(e.target.value)}
            placeholder="Enter Your Account Number"
            type="number"
          />
          <Label htmlFor="account_number">{t("ac_number")}</Label>
        </FormGroup>
        <FormGroup floating className="p-0 w-100">
          <Input
            style={{ border: "1px solid #B0B0B0", width: "100%" }}
            required
            id="ifsc_number"
            value={ifsc}
            // disabled={!disableEditDetail}
            onChange={e => setIfsc(e.target.value)}
            placeholder="Enter Your IFSC Number"
            type="text"
          />
          <Label htmlFor="ifsc_number">{t("ifsc_code")}</Label>
        </FormGroup>
        <FormGroup floating className="p-0 w-100">
          <Input
            style={{ border: "1px solid #B0B0B0", width: "100%" }}
            required
            id="account_name"
            value={name}
            // disabled={!disableEditDetail}
            onChange={e => setName(e.target.value)}
            placeholder="Enter Your Account Holder Name"
            type="text"
          />
          <Label htmlFor="account_name">{t("name")}</Label>
        </FormGroup>
        <FormGroup floating className="p-0 w-100">
          <Input
            style={{ border: "1px solid #B0B0B0", width: "100%" }}
            required
            id="bank_name"
            value={bankName}
            // disabled={!disableEditDetail}
            onChange={e => setBankName(e.target.value)}
            placeholder="Enter your Bank Name"
            type="text"
          />
          <Label htmlFor="bank_name">Bank Name</Label>
        </FormGroup>
        <Row
          style={{
            background: "#FAFAFA",
            padding: "10px",
            margin: "10px 0px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col className="p-0" style={{ color: "#CC7E02" }}>
            <Button
              disabled={hasdisablebtn && disableUploadbtn}
              style={{
                width: "max-content",
                background: `${disableUploadbtn ? "#ccc" : "#3A69DD"}`,
                color: "white",
                border: "none",
                padding: "0.6rem 2rem",
                marginTop: "0.5rem",
                fontWeight: "600",
                borderRadius: "20px",
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              {disableUploadbtn ? (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Submit"
              )}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default BankAccountModel;
