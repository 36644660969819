import { Col, Image, Modal, Row } from "react-bootstrap";
import { useAuth } from "../../context/auth-context";
import { Button, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { asyncWrap } from "../../utils/utils";
import { useHistory } from "react-router";
import { BsFillCameraFill } from "react-icons/bs";

const UPI360Model = ({ show, setShow, userDetails }) => {
  const [upiId, setUpiId] = useState(userDetails?.UpiId);
  const [upiVerify, setUpiVerify] = useState(userDetails?.IsUPIVerify);
  const [hasdisablebtn, setHadDisableBtn] = useState(true);
  const [disableUploadbtn, setDisableUploadbtn] = useState(false);
  const { user } = useAuth();
  const history = useHistory();
  const maxSize = 10 * 1024 * 1024;

  useEffect(() => {
    // getUserProfile();
    // eslint-disable-next-line
  }, []);

  const getUserProfile = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (error?.response?.status === 401) {
      Swal.fire("Please Logout and login again");
      return;
    }
    if (!result) {
      console.log(error.response);
      return;
    } else {
      setUpiId(result?.data?.data?.UpiId);
      setUpiVerify(result.data?.data?.IsDrivingLicenceVerify);
    }
  };

  const handleSubmit = async () => {

    if (upiVerify) {
      Swal.fire("Your information already verify successfully!")
      return
    }

    if (upiId === "") {
      Swal.fire("Please Enter Upi id Correctly");
      return;
    }
    const [error, result] = await asyncWrap(
      axios.post("verifyKyc/verify-advance-upi", {
        upi: upiId,
      })
    );
    if (!result) {
      Swal.fire(error.response.data.data.message);
      history.replace("/home");
    } else if (result.data?.success) {
      const [userError, userResult] = await asyncWrap(
        axios.post("/editProfile", {
          userType: user?.profile?.roleName || user?.roleName,
          userId: user?.profile?.userId || user.userId,
          upiId: upiId,
          isUpiVerify: true,
          bankAddress:
            result?.data?.data?.ifsc_details?.bank +
            "  " +
            result?.data?.data?.ifsc_details?.branch +
            "  " +
            result?.data?.data?.ifsc_details?.address,
        })
      );
      if (!userResult) {
        Swal.fire(error.response.data.data.message);
        history.replace("/home");
      } else if (userResult.data?.success) {
        Swal.fire(userResult.data.message).then(() => {
          history.replace("/home");
        });
      } else {
        Swal.fire(userResult?.data?.message).then(() => {
          window.location.reload();
        });
      }
    } else {
      Swal.fire(result?.data?.message).then(() => {
        window.location.reload();
      });
    }
  };

  return (
    <Modal fullscreen={true} show={show}>
      <Modal.Body
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "50px",
        }}
      >
        <Row className="w-100" style={{ width: "100%" }}>
          {/* <Col
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          > */}
          {/* <Form className="w-100"> */}
          <FormGroup floating className="p-0">
            <Input
              style={{ border: "1px solid #B0B0B0", width: "100%" }}
              required
              onChange={e => {
                setUpiId(e.target.value);
              }}
              value={upiId}
              placeholder="Enter UPI Number"
              type="text"
            />
            <Label>UPI Number</Label>
          </FormGroup>
          {/* </Form> */}
          {/* </Col> */}
        </Row>
        <Row
          style={{
            background: "#FAFAFA",
            padding: "10px",
            margin: "10px 0px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col className="p-0" style={{ color: "#CC7E02" }}>
            <Button
              disabled={hasdisablebtn && disableUploadbtn}
              style={{
                width: "max-content",
                background: `${disableUploadbtn ? "#ccc" : "#3A69DD"}`,
                color: "white",
                border: "none",
                padding: "0.6rem 2rem",
                marginTop: "0.5rem",
                fontWeight: "600",
                borderRadius: "20px",
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              {disableUploadbtn ? (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Submit"
              )}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default UPI360Model;
