import PanCardModal from "../component/KycVerify/PanCardModal";
import AadharCardModal from "../component/KycVerify/AadharCardModal";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import CloseModalTab from "../component/Nav/CloseModalTab";
import GstinCardModal from "../component/KycVerify/GstinCardModal";
import { useAuth } from "../context/auth-context";
import DLModel from "../component/KycVerify/DLModel";
import GSTModel from "../component/KycVerify/GSTModel";
import Pan360Verification from "../component/KycVerify/Pan360Model";
import BankAccountModel from "../component/KycVerify/BankAccountModel";
import PersonAliveModel from "../component/KycVerify/PersonAliveModel";
import IFSCModel from "../component/KycVerify/IFSCModel";
import UPIFreeModel from "../component/KycVerify/UPIFreeModel";
import UPI360Model from "../component/KycVerify/UPI360Model";
import UPIFetchMobileModel from "../component/KycVerify/UPIFetchMobileModel";
import PanOCRModel from "../component/KycVerify/PanOCRModel";
import AadharOCRModel from "../component/KycVerify/AadharOCRModel";
import { asyncWrap } from "../utils/utils";
import Swal from "sweetalert2";
import axios from "axios";

const KycVerify = () => {
  // eslint-disable-next-line
  const [show, setShow] = useState(true);
  const [showPanCardModal, setShowPanCardModal] = useState(false);
  const [showAadharCardModal, setShowAadharCardModal] = useState(false);
  const [showGstinCardModal, setShowGstinCardModal] = useState(false);
  const [showDLModel, setShowDLModel] = useState(false);
  const [showGSTModel, setShowGSTModel] = useState(false);
  const [showBankAccountModel, setShowBankAccountModel] = useState(false);
  const [showPan360Model, setShowPan360Model] = useState(false);
  const [showPersonAliveModel, setShowPersonAliveModel] = useState(false);
  const [showIFSCModel, setShowIFSCModel] = useState(false);
  const [showUPIFreeModel, setShowUPIFreeModel] = useState(false);
  const [showUPI360Model, setShowUPI360Model] = useState(false);
  const [showFetchUpiModel, setShowFetchUpiModel] = useState(false);
  const [showPanOCRModel, setShowPanOCRModel] = useState(false);
  const [showAadharOCRModel, setShowAadharOCRModel] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [cmsValues, setCmsValues] = useState();
  const { user } = useAuth();

  const getUserProfile = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (error?.response?.status === 401) {
      Swal.fire("Please Logout and login again");
      return;
    }
    if (!result) {
      console.log(error.response);
      return;
    } else {
      setUserDetails(result?.data?.data);
    }
  };

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get("/cmsValues"));
    if (!result) {
      console.log(error);
      return;
    }
    setCmsValues(JSON.parse(result.data?.data?.KycConfig[0]?.Descriptions));
    console.log(JSON.parse(result.data?.data?.KycConfig[0]?.Descriptions));
  };

  useEffect(() => {
    getUserProfile();
    getCmsValues();
  }, []);

  function checkEnable(controlName) {
    if (cmsValues) {
      for (let control of cmsValues) {
        if (control.ControlName === controlName) {
          return control.enable;
        }
      }
      return null; // Control name not found
    }
  }

  return (
    <>
      <PanCardModal show={showPanCardModal} setShow={setShowPanCardModal} />
      <AadharCardModal
        show={showAadharCardModal}
        setShow={setShowAadharCardModal}
      />
      {userDetails && (
        <GstinCardModal
          show={showGstinCardModal}
          setShow={setShowGstinCardModal}
          userDetails={userDetails}
        />
      )}
      {userDetails && (
        <DLModel
          show={showDLModel}
          setShow={setShowDLModel}
          userDetails={userDetails}
        />
      )}
      {userDetails && (
        <GSTModel
          show={showGSTModel}
          setShow={setShowGSTModel}
          userDetails={userDetails}
        />
      )}
      {userDetails && (
        <Pan360Verification
          show={showPan360Model}
          setShow={setShowPan360Model}
          userDetails={userDetails}
        />
      )}
      {userDetails && (
        <BankAccountModel
          show={showBankAccountModel}
          setShow={setShowBankAccountModel}
          userDetails={userDetails}
        />
      )}
      {userDetails && (
        <PersonAliveModel
          show={showPersonAliveModel}
          setShow={setShowPersonAliveModel}
          userDetails={userDetails}
        />
      )}
      {userDetails && (
        <IFSCModel
          show={showIFSCModel}
          setShow={setShowIFSCModel}
          userDetails={userDetails}
        />
      )}
      {userDetails && (
        <UPIFreeModel
          show={showUPIFreeModel}
          setShow={setShowUPIFreeModel}
          userDetails={userDetails}
        />
      )}
      {userDetails && (
        <UPI360Model
          show={showUPI360Model}
          setShow={setShowUPI360Model}
          userDetails={userDetails}
        />
      )}
      {userDetails && (
        <UPIFetchMobileModel
          show={showFetchUpiModel}
          setShow={setShowFetchUpiModel}
          userDetails={userDetails}
        />
      )}
      {userDetails && (
        <PanOCRModel
          show={showPanOCRModel}
          setShow={setShowPanOCRModel}
          userDetails={userDetails}
        />
      )}
      {userDetails && (
        <AadharOCRModel
          show={showAadharOCRModel}
          setShow={setShowAadharOCRModel}
          userDetails={userDetails}
        />
      )}
      <Modal fullscreen={true} show={show}>
        {/* <Modal.Header> */}
        {/* <SecoundaryHeader
            text={t("kyc_verification")}
            sideHide="false"
            onBack={() => {
              history.push("/home");
              setShow(false);
            }}
          /> */}
        {/* </Modal.Header> */}
        <Modal.Body
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "50px",
          }}
        >
          <div className="d-flex flex-column gap-4">
            <CloseModalTab />
            <div
              onClick={() => {
                setShowAadharCardModal(true);
              }}
              className="d-flex flex-column shadow p-3 rounded-4 text-center"
            >
              <div
                style={{
                  color: "rgb(58, 105, 221)",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Aadhar Card Verification
              </div>
              <img
                style={{ width: "100%" }}
                alt="aadharcard"
                src="/assets/images/kyc/aadharimg.jpg"
              />
            </div>
            {(user?.profile?.roleName || user?.roleName) === "Retailer" ? (
              <div
                onClick={() => {
                  setShowGstinCardModal(true);
                }}
                className="d-flex flex-column shadow p-3 rounded-4 text-center"
              >
                <div
                  style={{
                    color: "rgb(58, 105, 221)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  GSTIN Verification
                </div>
                <img
                  style={{ width: "100%" }}
                  alt="aadharcard"
                  src="/assets/images/kyc/gstinimg.jpg"
                />
              </div>
            ) : (
              ""
            )}
            <div
              onClick={() => {
                setShowPanCardModal(true);
              }}
              className="d-flex flex-column shadow p-3 rounded-4 text-center"
            >
              <div
                style={{
                  color: "rgb(58, 105, 221)",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Pan Card Verification
              </div>
              <img
                style={{ width: "100%", borderRadius: "10px" }}
                alt="pancard"
                src="/assets/images/kyc/pancard.png"
              />
            </div>

            {checkEnable("Driving license") && (
              <div
                onClick={() => {
                  setShowDLModel(true);
                }}
                className="d-flex flex-column shadow p-3 rounded-4 text-center"
              >
                <div
                  style={{
                    color: "rgb(58, 105, 221)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Driving Licence Verification
                </div>
              </div>
            )}
            {checkEnable("GSTIN") && (
              <div
                onClick={() => {
                  setShowGSTModel(true);
                }}
                className="d-flex flex-column shadow p-3 rounded-4 text-center"
              >
                <div
                  style={{
                    color: "rgb(58, 105, 221)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  GST Verification
                </div>
              </div>
            )}
            {checkEnable("PAN 360") && (
              <div
                onClick={() => {
                  setShowPan360Model(true);
                }}
                className="d-flex flex-column shadow p-3 rounded-4 text-center"
              >
                <div
                  style={{
                    color: "rgb(58, 105, 221)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Pan 360 verification
                </div>
              </div>
            )}
            {checkEnable("Back Account") && (
              <div
                onClick={() => {
                  setShowBankAccountModel(true);
                }}
                className="d-flex flex-column shadow p-3 rounded-4 text-center"
              >
                <div
                  style={{
                    color: "rgb(58, 105, 221)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Bank Account Verification
                </div>
              </div>
            )}

            {checkEnable("IFSC Code") && (
              <div
                onClick={() => {
                  setShowIFSCModel(true);
                }}
                className="d-flex flex-column shadow p-3 rounded-4 text-center"
              >
                <div
                  style={{
                    color: "rgb(58, 105, 221)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  IFSC Verification
                </div>
              </div>
            )}

            {checkEnable("PAN OCR") && (
              <div
                onClick={() => {
                  setShowPanOCRModel(true);
                }}
                className="d-flex flex-column shadow p-3 rounded-4 text-center"
              >
                <div
                  style={{
                    color: "rgb(58, 105, 221)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Pan OCR verification
                </div>
              </div>
            )}

            {checkEnable("Aadhar OCR") && (
              <div
                onClick={() => {
                  setShowAadharOCRModel(true);
                }}
                className="d-flex flex-column shadow p-3 rounded-4 text-center"
              >
                <div
                  style={{
                    color: "rgb(58, 105, 221)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Aadhar OCR verification
                </div>
              </div>
            )}

            {checkEnable("UPI ID Free") && (
              <div
                onClick={() => {
                  setShowUPIFreeModel(true);
                }}
                className="d-flex flex-column shadow p-3 rounded-4 text-center"
              >
                <div
                  style={{
                    color: "rgb(58, 105, 221)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  UPI verification
                </div>
              </div>
            )}

            {checkEnable("Fetch UPI") && (
              <div
                onClick={() => {
                  setShowFetchUpiModel(true);
                }}
                className="d-flex flex-column shadow p-3 rounded-4 text-center"
              >
                <div
                  style={{
                    color: "rgb(58, 105, 221)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Fetch Upi Phone Number verification
                </div>
              </div>
            )}

            {checkEnable("UPI ID 360") && (
              <div
                onClick={() => {
                  setShowUPI360Model(true);
                }}
                className="d-flex flex-column shadow p-3 rounded-4 text-center"
              >
                <div
                  style={{
                    color: "rgb(58, 105, 221)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  UPI 360 verification
                </div>
              </div>
            )}

            {/* {checkEnable("Liveliness Check") && (
              <div
                onClick={() => {
                  setShowPersonAliveModel(true);
                }}
                className="d-flex flex-column shadow p-3 rounded-4 text-center"
              >
                <div
                  style={{
                    color: "rgb(58, 105, 221)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Person Alive
                </div>
              </div>
            )} */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default KycVerify;
